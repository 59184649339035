import '../common/polyfills';
import carousel from '../common/carousel';
import lazyLoadImages from '../common/lazy-load';
import setDate from '../common/date';
import ads from '../common/ads';
import tracking from '../common/tracking';
import poll from '../common/poll';
import _ from '../common/utility';
import callouts from './modules/callouts';
import news from '../common/news';
import alerts from './modules/alerts';
import nav from './modules/nav';
import trending from './modules/trending';
import objectFitImages from 'object-fit-images';
import featured from './modules/featured';
import comments from '../common/comments';

$(() => {

    _.initJQueryPlugin();
    nav();
    trending();

    carousel();
    lazyLoadImages();
    setDate();
    poll();
    news();

    tracking();
    callouts();
    alerts();

    objectFitImages('section:not(.hp_partners) img');

    //$('.trend').remove(); //temp hack for removing trending in byline

   // $(".hp_conf-coverage li:nth-child(2)")
   //     //.html("<div style='width:300px; height:200px; background-color:grey'>") //temp hack for blog in conference takeover
   //     .addClass("hasBlog");

    ads();
    
    featured();
	
	comments();
    
});








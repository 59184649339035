import _ from './utility';

function news () {
	if (_.isCOE() && $('.coe-v2').length) return;
	
	let adCont = $(".latest_news .ad-container"),
	    articles = ".latest_news ul.articles",
	    evenArticles = $(`${articles} li:nth-child(2n)`),
	    column2 = $("<ul class='column2'></ul>");

	if(breakpoint >= 3) {
		$(articles).addClass("column1");
		evenArticles.appendTo(column2);
		column2.appendTo(".latest_news .section-container");
	}
	if(breakpoint == 2) {
		if ($(`${articles} li`).length >= 6) {
			adCont.clone().insertBefore(`${articles} li:nth-child(6)`);
			//PPE-321775 make sure moved image is visible on page load
			if (typeof $(`${articles} li:nth-child(7) img`).attr("data-src") !== 'undefined' && $(`${articles} li:nth-child(7) img`).attr("data-src") !== false) {
				$(`${articles} li:nth-child(7) img`).attr("src", $(`${articles} li:nth-child(7) img`).attr("data-src") + "?interpolation=lanczos-none&resize=360:*");
			}
		} else {
			adCont.clone().insertAfter(`${articles} li:last-child`);
		}
	    adCont.remove();

	    $(document).one('dfpRenderComp', function () {
	        let adCont = $(".latest_news .ad-container");
	        console.log(adCont.outerHeight());
	        if(adCont.outerHeight()>=600){
	            adCont.nextAll("*:lt(2)").insertBefore(adCont);
	        }
	    });
	}
}

export default _.catch(news);

import jqueryBridegt from 'jquery-bridget';
import Flickity from 'flickity';

const utility = {
    throttle: function(fn, threshold){
        threshold = threshold || 100;
        let last;
        let timer;
        return function(){
            const now = +new Date;
            const args = arguments;
            const self = this;
            if(last && now < last + threshold){
                clearTimeout(timer);
                last = now;
                timer = setTimeout(function(){
                    fn.apply(self, args);
                }, threshold)
            } else {
                last = now;
                fn.apply(self, args);
            }
        };
    },

    debounce: function (fn, threshold) {
        threshold = threshold || 100;
        let timer;
        return function () {
            const self = this;
            const args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                fn.apply(self, args);
            }, threshold);
        };
    },

    objectFitForIE: function (selector) {
        let userAgent, ieReg, ie;
        userAgent = window.navigator.userAgent;
        ieReg = /msie|Trident.*rv[ :]*11\./gi;
        ie = ieReg.test(userAgent);

        if (ie) {
            $(selector).find('img').each(function () {
                const $img = $(this);
                const imgUrl = $img.prop('src');
                if (imgUrl) {
                    $img.wrap('<div>').parent().css('backgroundImage', 'url(' + imgUrl + ')').addClass('ie-object-fit');
                }
            });
        }
    },

    lastVisibleByline:function(){
        $('.byline span').removeClass('lastVisible');
        $('.byline').find('span:not(.viafoura-commentcount,.livefyre-commentcount):visible:last').addClass('lastVisible');
    },

    isMobile: function () {
        return typeof window.orientation !== 'undefined' || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },

    shuffle:  function (array) {
        let currentIndex = array.length, temporaryValue, randomIndex;

        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    },

    getBreakpoint: function () {
        if (window.innerWidth <= 767) {
            return 1;
        }else if(window.innerWidth >= 768 && window.innerWidth <= 1023 ) {
            return 2;
        } else if(window.innerWidth >= 1024 && window.innerWidth <= 1280) {
            return 3;
        } else {
            return 4;
        }
    },

    isCOE: function () {
        return typeof isCOE !== 'undefined' && isCOE;
    },

    isRC: function () {
        return $('main').hasClass('rc');
    },

    getSiteId:function(){
        const siteId = {
            'us-qa.': 303779,
            'de-qa.': 303720,
            'es-qa.': 305960,
            'fr-qa.': 304071,
            'pt-qa.': 306028,
            'us-staging.': 303779,
            'de-staging.': 303720,
            'es-staging.': 305960,
            'fr-staging.': 304071,
            'pt-staging.': 306028,
            'us-': 324818,
            'de-': 312651,
            'es-': 380095,
            'fr-': 339357,
            'pt-': 381100,
        };
        return siteId[`${locale}-${getDomain().replace(/\d+/g,'')}`];
    },

    // trucate string to a whole word closest to n character and append ellipsis
    truncate: function (string, n ) {
        let finished = false;
        return string.split(' ').reduce(function(accum, current){
            if (accum.length > n || finished) {
                return accum;
            }
            var temp = accum + current;
            if (temp.length > n) {
                finished = true;
                return accum + ' ...';
            } else {
                return accum + ' ' + current;
            }
        }, '')
    },

    catch : (fn) => (...params) => {
        try {
            fn.apply(null, params);
        } catch (e) {
            console.log(e);
        }
    },

    initJQueryPlugin: () => {
        jqueryBridegt('flickity', Flickity, window.jQuery);
    },
    getImageServer:function(){
        let isLocalhost = document.location.host.indexOf('localhost')>-1;
        let domain = isLocalhost ? 'localhost.' : this.getDomain();

        let imageServer =  `img.${domain}medscapestatic.com`;
        if(isLocalhost){
            let port = document.location.port.length>0 ? ":"+document.location.port : "";
            imageServer+=port;
        }

        return imageServer;
    },
    getDomain: (profreg)=>{
        if(window.getDomain){
            return window.getDomain(profreg);
        }
        else{
            let _domain ="";
            let _host = window.location.host.split('.');
            if(_host[0]==='localhost'){
                _domain = _host[0]+".";
            }
            else if (_host.length == 4){
                _domain = window.location.host.split('.')[1] + ".";
            }
            else if (_host.length == 5){
                _domain = window.location.host.split('.')[1] + "."+ window.location.host.split('.')[2] + ".";
            }
            else if(profreg=="profreg"||profreg=="login"){
                _domain = _domain.replace('staging.','').replace('proddev.','');
            }
            return _domain;
        }

    },
    hpCardlayoutImgCrop: () => {    // Fix image aspect ratio, crop images dynamically
        if (document.querySelector(".hp_cardlayout ul li") && 
            !document.querySelector(".hp_cardlayout ul li").classList.contains("card-small-thumb")) {
            let images = document.querySelectorAll(".hp_cardlayout ul li img");
            let width = document.querySelector(".hp_cardlayout ul li").getBoundingClientRect().width;

            for (let i = 0; i < images.length; i ++) {
                images[i].style.width = `${width}px`;
                images[i].style.height = `${0.56 * width}px`;
            }
        }
    }
};

export default utility;
import _ from './utility';

function tracking () {

    $('#specialtyToggle').on('click',(e)=>{
        window.wmdPageLink('hp-special_more', this);
    })


    // specialty list
    $('.hp_specialty ul li a').each(function(index) {
        $(this).on('click',() => {
            wmdTrack(`hp-special_${index + 1}`, this);
        });
    });


    // resource center
    let resoureCenterLinks = $('.hp_resource-center ul li a');

    //Special condition for digitalHub omnitures and to avoid common last link omniture
    if(resoureCenterLinks.parents("main.digital_hub").length === 1) {
        resoureCenterLinks.each(function(index) {
            $(this).on('click',() => {
                wmdTrack(`madh-rc_${index + 1}`, this);
            });
        });
    } else {
    // general tracking
    const trackSelectors = {
        resourceCenterMore: '.hp_resource-center li:last'
    };

    const trackValue = {
        resourceCenterMore: 'hp-rc_more'
    };

        //by default condition for last link in resource center
    for (let key in trackSelectors) {
        if (trackSelectors.hasOwnProperty(key)) {
            $(trackSelectors[key]).on('click', () => {
                wmdTrack(trackValue[key], this);
            });
        }
    }

    let len = resoureCenterLinks.length;
    resoureCenterLinks.each(function(index) {
        if (index !== len - 1) {
            $(this).on('click',() => {
                wmdTrack(`hp-rc_${index + 1}`, this);
            });
        }
    });
    }

    // mrkcallouts
    $('.hp_mrktcallouts .carousel-item').each(function(index) {
        $(this).on('click',() => {
            wmdTrack(`hp-mkt_${index + 1}`, this);
        });
    });

    // columnists
    let columnTrackVal = "hp-column";
    if(_.isRC()){columnTrackVal = "rc-column";}
    $('#columnists .carousel-item').each(function(index) {
            $(this).on('click',() => {
                wmdTrack(`${columnTrackVal}_${index + 1}`, this);
            });
    });

     // trending today
    $(document).on('click', '.hp_trending li', function() {
        let index = $(this).index();
        wmdTrack(`hp-trending_${index + 1}`, this);
    });

    // steering committee
    $('.committee-item').each(function (index) {
        let link = $(this).find('a');
        link.on('click',() => {
            if (_.isCOE()) {
                wmdTrack(`coe-scs`, this)
            } else {
                wmdTrack(`rc-committee`, this);
            }
        });
    });

    // tracking for all the section-buckets
    $('section[data-track]').each(function() {
        // skip resources section in digital hub as it has different mlink
        if(!$(this).hasClass('hp_resources')){
            let trackVal = $(this).attr("data-track");
            $(this).find('li').each(function(index) {
                let link = $(this).find('a');
				let href = '';
				if (link.length > 0) {
					href = link[0].href;
				}
                if($(this).hasClass('blog')){
                    link.on('click',() => {
                        wmdTrack(`hp-liveblog`, link);
                    });
                }
                else{
                    link.on('click',(event) => {
						let domain = href.indexOf('medscape.com') != -1 ? true : false;
						if (!domain) {
							event.preventDefault();
							document.addEventListener('afterTrackingSent', function() { // beacon call callback event
								window.location.href = href; // proceed to link href
							});
							setTimeout(function() { // backup timeout to redirect in case of omniture call failure
								window.location.href = href; // proceed to link href
							}, 500);
							wmdPageLink(`${trackVal}_${index + 1}`, link[0])
						} else {
							wmdTrack(`${trackVal}_${index + 1}`, link[0]);
						}                    
                    });
                }
            });
            let moreLink = $(this).find('span.more a');
			let moreHref = '';
			if (moreLink.length > 0) {
				moreHref = moreLink[0].href;
			}
            moreLink.on('click',(event) => {
                let domain = moreHref.indexOf('medscape.com') != -1 ? true : false;
				if (!domain) {
					event.preventDefault();
					document.addEventListener('afterTrackingSent', function() { // beacon call callback event
                        window.location.href = moreHref; // proceed to link href
                    });
					setTimeout(function() { // backup timeout to redirect in case of omniture call failure
                        window.location.href = moreHref; // proceed to link href
                    }, 500);
					wmdPageLink(`${trackVal}_more`, moreLink[0]);
				} else {
					wmdTrack(`${trackVal}_more`, moreLink[0]);
				}                   
            });
        }
    });

    // infographic section
	let infographic = document.querySelectorAll('main.digital_hub #disease_articles ul li');
	let infographicLen = infographic.length;
	infographic.forEach((el, index) => {
		if (index <= infographicLen - 1) {
			el.querySelectorAll('a').forEach((a) => {
				a.addEventListener('click', () => {
					wmdPageLink(`madh-lnk_${index + 1}`, a);
				});
			});
		}
	});

    //resources section in digital hub
    let resourceCards = document.querySelectorAll('main.digital_hub .hp_resources .resource-card');
    resourceCards.forEach((card, cardIndex) => {
        let links = card.querySelectorAll('li a');
        links.forEach((link,index) => {
            link.addEventListener('click', () => {
                // @ts-ignore
                wmdPageLink(`madh-xtlnk_${cardIndex + 1}-${index + 1}`, link);
            });
        });
    });

    let eventCardsLinks = document.querySelectorAll('main.digital_hub .hp_live .events-card a');
    eventCardsLinks.forEach((link, cardIndex) => {
        link.addEventListener('click', () => {
            // @ts-ignore
            wmdPageLink(`madh-lvrg_${cardIndex + 1}`, link);
        });
    });
}

export default _.catch(tracking);
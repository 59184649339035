import _ from './utility';
import objectFitImages from 'object-fit-images';
const $callouts = $('#callouts');
const observableConfig = { attributes: true, childList: false, subtree: false };
const defaultConfig = {
    cellAlign: 'left',
    groupCells: true,
    lazyLoad: true
};
const defaultTracking = {
    change: 'hp-mkt_carousel'
}
class Carousel {
    constructor(carouselEl, config = defaultConfig, tracking = defaultTracking) {
        this.el = carouselEl;
        this.$el = $(carouselEl);
        this.config = config;
        this.tracking = tracking;
        this.$carousel = this.$el.flickity(config);
        this.flkty = this.$carousel.data('flickity');
        this.nextButton = null;
        this._nextButton = null;
        this.previousButton = null;
        this._previousButton = null;
        this.initArrowButtons();
        this.initEventsListener();
    }
    initArrowButtons() {
        let buttons = [...this.el.querySelectorAll('.flickity-prev-next-button')];
        buttons.forEach(button => {
/* 
    Create a clone of the original arrow buttons. 
    Flickity disable click events listeners on the default buttons
*/
            let newButton = button.cloneNode(true);
            button.style.display = "none";
            this.el.parentNode.appendChild(newButton);
            const isNext = newButton.classList.contains("next");
            if (isNext) {
                this.nextButton = newButton;
                this._nextButton = button;
            } else {
                this.previousButton = newButton;
                this._previousButton = button;
            }
            newButton.addEventListener('click', (e) => {
                if (isNext) {
                    this.$carousel.flickity("next");
                } else {
                    this.$carousel.flickity("previous");
                }
            })
        })
    }
    initEventsListener() {
        this.$carousel.on('change.flickity', (e, index) => {
/*
    Sync the cloned buttons disable state with the original buttons
*/
            if (this._previousButton.getAttribute("disabled") != null) {
                this.previousButton.setAttribute('disabled', "");
            } else {
                this.previousButton.removeAttribute('disabled');
            }
            if (this._nextButton.getAttribute("disabled") != null) {
                this.nextButton.setAttribute('disabled', "");
            } else {
                this.nextButton.removeAttribute('disabled');
            }

            window.wmdPageLink(this.tracking.change);

        })
    }
}

function carouselAds() {
    if ($callouts.length == 0) {
        return;
    }
    const calloutsEl = $callouts[0];
    let $calloutsSection = $callouts.parent('section');

    let adUnitEls = [...calloutsEl.getElementsByClassName('AdUnit')];
    if ($callouts.find('.carousel-item').length == 0) {
        return;
    }
    $calloutsSection.show();
    const config = {
        cellAlign: 'left',
        groupCells: true,
        lazyLoad: true
    };

    let adConfig = config;
    if ($callouts.find('.carousel-item').length === 4) {
        $callouts.addClass('unwrapAround');
    } else if ($callouts.find('.carousel-item').length > 4) {
        adConfig = $.extend(config, { 'cellAlign': 'center' });
        $callouts.addClass('wrapAround');
    } else {
        adConfig = $.extend(config, { 'cellAlign': 'left', groupCells: true, wrapAround: false });
        $callouts.addClass('unwrapAround');
    }
    let adCarousel = new Carousel($callouts[0], adConfig);

    adUnitEls.forEach((el, i) => {
        let $el = $(el);
        let carouselItem = $el.parent('.carousel-item')[0];
        let mutationObservable = new MutationObserver(observableCallback);
        let timer = null;
/* 
    Remove blank ads spots from the carousel
*/
        function observableCallback(mutationsList) {

            if ($el.hasClass("blank-ad")) {
                $callouts.flickity('remove', carouselItem);
                mutationObservable.disconnect();
                clearTimeout(timer);
            }
        }
        if ($el.hasClass("blank-ad")) {
            $callouts.flickity('remove', carouselItem);
        } else {
            mutationObservable.observe(el, observableConfig);
            timer = setTimeout(function() {
                mutationObservable.disconnect();
            }, 5000)
        }
    })
}

function carousel() {

    $('.carousel-container img').attr('data-no-lazyload', true);


    carouselAds();
    // randomize items first then initialize carousel
    const $columnists = $('#columnists');
    if ($columnists.length > 0) {
        const $columnistItems = $columnists.find('.carousel-item').detach().toArray();
        _.shuffle($columnistItems).forEach(val => {
            $columnists.append(val);
        });
        let columnistsConfig = {
            wrapAround: true,
            groupCells: true,
            lazyLoad: true,
            cellAlign: 'left'
        }
        if ($columnists.find('.carousel-item').length === 4) {
            $columnists.addClass('no-desktop-carousel')
            columnistsConfig = {
                wrapAround: true,
                groupCells: true,
                lazyLoad: true,
                watchCSS: true
            }
        }
        $columnists.flickity(columnistsConfig);
    }
    const $mtv = $('#mtv');
    if ($mtv.length > 0) {
        let mtvConfig = {};
        if ($mtv.find('.carousel-item').length < 3) {
            mtvConfig = {
                wrapAround: false,
                lazyLoad: true
            }
            $mtv.addClass('unwrapAround');
        } else {
            mtvConfig = {
                wrapAround: true,
                lazyLoad: true
            }
            $mtv.addClass('wrapAround');
        }

        let mtvCarousel = new Carousel($mtv[0], mtvConfig);
    }
    const $coe = $('#coe_carousel');
    if ($coe.length > 0) {
        let coeConfig = {};
        if ($coe.find('.carousel-item').length < 3) {
            coeConfig = {
                wrapAround: false,
                lazyLoad: true,
                autoPlay: 5000
            }
            $coe.addClass('unwrapAround');
        } else {
            coeConfig = {
                wrapAround: true,
                lazyLoad: true,
                autoPlay: 5000
            }
            $coe.addClass('wrapAround');
        }

        let mtvCarousel = new Carousel($coe[0], coeConfig);
    }
    const reportsDiv = $('.hp_cardlayout ul');
    if (reportsDiv.length > 0) {
        reportsDiv.flickity({
            watchCSS: true
        });
    }

	$('.hp_experts ul').flickity({
		cellAlign: 'left',
		groupCells: true,
		lazyLoad: true
	});

	$('.hp_image-carousel ul').flickity({
		cellAlign: 'left',
		groupCells: true,
		lazyLoad: true
	});

    // Setting image dimensions for cropping images to correct aspect ratio for hp-cardlayout carousel
    _.hpCardlayoutImgCrop();
    window.addEventListener("resize", _.hpCardlayoutImgCrop);

    // market callouts image crop for carousel image
    objectFitImages('.carousel-item img');
}

export default carousel;
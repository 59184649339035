import _ from './utility';

function poll () {
    const isNewsHomepage = window.isNewsHomepage || false;
    const $poll = $('.hp_poll');
    const isCoePage = $('main').hasClass('coe');
    let qnaApi = window.qna;
    const defaultDataset = {
        showViewResult: !isCoePage,
        showPollFilter: false,
        showTotalResponse: false,
        showReturnToQuestion: !isCoePage,
        showSubmitIndicator: true
    }
    

    const datasetKeys = Object.keys(defaultDataset);
    if ($poll.length) {
        if(typeof qnaApi !== 'undefined'){
            load();
        }
        else{
            /*
                Load Q&A library if it isn't available
            */
            const scriptPath = 'medscape-core/qna-lib/js/qna.min.js';
            let url = `//${_.getImageServer()}/${scriptPath}`;
            $.getScript(url).then(()=>{
                qnaApi = window.qna;
                load();
            })
        }

    }

    function load(){
       const qnas = [...document.getElementsByClassName('qna-render')];
       qnas.forEach( (qnaEl)=>{
            let $qnaEl = $(qnaEl);
            let dataset = $qnaEl[0].dataset;
            datasetKeys.forEach( key=>{
                dataset[key] = dataset[key] || defaultDataset[key];
            })
			dataset.questionnaireid = dataset.questionnaireid.trim();
            qnaApi.createArticlePoll(qnaEl).then( (qna)=>{
                const data = qna.formDataModel.data;
                const isGated = data.gated;
                if(isGated && Medscape.User.isLoggedIn()==false){
                    $poll.remove();
                }
                else{
                    let $qnaEl = $(qnaEl);
                    //$qnaEl.find('.qna-button').text('next');
                    $qnaEl.on('submit','form',(e)=>{
                        if(_.isRC()) {
                            wmdPageLink('rc-poll');
                        }
                        else {
                            wmdPageLink('hp-poll');
                        }
                })
                }
            });
       })

    }
}

export default poll;


import _ from './utility';

function lazyLoadImages(){

    let lazyloadingImages = {
        init: function() {
            $('img[data-src]').each(function(index,el) {
                const dataSrc = $(this).attr("data-src");
                const noLazyload = $(this).attr("data-no-lazyload");
                if (noLazyload) {
                    $(this).attr("src", dataSrc);
                }
                else{
                    $(this).addClass('resizeImage');
                    if ($(this).next().hasClass("imgVideo") && breakpoint>2){
                        $(this).parent().addClass("eleVideo");
                    }
                }
            });

            $(window).on("scroll", lazyloadingImages.resizeOnScroll);
            //make sure images in view on load are visible
            lazyloadingImages.resizeOnScroll();
            setTimeout(lazyloadingImages.resizeOnScroll, 100);
        },
        imageResize: function(dataSrc, _this) {
            _this.attr("src",dataSrc+"?interpolation=lanczos-none&resize=360:*");
        },
        scrollIntoView: function(elem) {
            let $elem = $(elem);
            let $window = $(window);

            let docViewTop = $window.scrollTop();
            let docViewBottom = docViewTop + $window.height();

            let elemTop = $elem.offset().top;

            return elemTop <= docViewBottom;
        },
        resizeOnScroll: function() {
            $('.resizeImage').each(function () {
                if (lazyloadingImages.scrollIntoView($(this))) {
                    lazyloadingImages.imageResize($(this).attr('data-src'), $(this));
                    $(this).removeClass('resizeImage');
                }
            });
            if ($('.resizeImage').length == 0) {
                $(window).off("scroll", lazyloadingImages.resizeOnScroll);
            }
        }

    }

    lazyloadingImages.init();

}

export default _.catch(lazyLoadImages);



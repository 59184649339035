import _ from '../../common/utility';

function trending () {
    let trendingDiv = $('.hp_trending .section-container'),
        pathArray = window.location.pathname.split('/'),
        leadTopicCenterID = pageParams.leadTopicCenterID,
        today = new Date(),
        dateStamp = `${today.getFullYear()}${today.getMonth()+1}${today.getDate()} `;
		
		if ($('.hp_trending [data-ssp]').length > 0 && !isNaN($('.hp_trending [data-ssp]').data().ssp)) {
			leadTopicCenterID = $('.hp_trending [data-ssp]').data().ssp;
		}
    
    //let trendingUrl = `/features/public/trending/${ssp}.json`;

    //let trendingUrl = `/features/public/trending/${pathArray[1]}.json`;

 // let trendingUrl = `//img.${getDomain()}medscapestatic.com/medscape-files/homepage/trending/${pathArray[1]}.json?${dateStamp}`;
  
    let trendingUrl = `//img.${getDomain()}medscapestatic.com/medscape-files/homepage/trending/${leadTopicCenterID}.json?${dateStamp}`;

  $.getJSON(trendingUrl, function (data) {
      const articles = data.articles.map(function (article,index) {
          const item = `<div class="index">${(index+1)}</div><div class="title"><a href="${article.url}">${article.title}</a></div>`;
            return item;
        });

        if (articles.length>=5) {
          const content = '<li>' + articles.join('</li><li>') + '</li>';
          trendingDiv.html(content);
        }
        else{
          $(".hp_trending").hide();
        }

        // initialize carousel
        trendingDiv.flickity({
            watchCSS: true
        });
    })
    .fail(function(error){
      $(".hp_trending").hide();
      console.log(error);
    });
}

export default _.catch(trending);
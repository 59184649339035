import _ from './utility';

function setDate(){

	const current = new Date();

	$('.timestamp').each(function() {
        const timestamp = Number($(this).attr('data-ts')),
              postingDate = $(this).html(),
              date = new Date(timestamp),
              newDate = calcDate(date, postingDate);
		$(this).html(newDate);
	});

	function calcDate(date, postingDate) {
        const diffMs = Math.abs(current-date),
              diffMins = Math.ceil(diffMs / 60000),
              diffHours = Math.round(diffMs / 3600000);

	    if(diffMins<60){
	        return translateMins(diffMins);
	    }
        else if(diffMins>=60&&diffMins<=90){
            return translateHr(diffHours);
        }
	    else if(diffHours<12){
	        return translateHrs(diffHours);
	    }
	    else
	        return (postingDate);
	}

	function translateMins(diffMins){
		const minsText = {
            'us': `${diffMins} minutes ago`,
            'de': `vor ${diffMins} Minuten`,
            'es': `Hace ${diffMins} minutos`,
            'fr': `Il y a ${diffMins} minutes`,
            'pt': `${diffMins} minutos atrás`,
        };
        return minsText[`${locale}`];
	}

    function translateHr(diffHour){
        const hourText = {
            'us': `${diffHour} hour ago`,
            'de': `vor einer ${diffHour} Stunde`,
            'es': `Hace ${diffHour} hora`,
            'fr': `Il y a ${diffHour} heure`,
            'pt': `${diffHour} hora atrás`,
        };
        return hourText[`${locale}`];
    }

	function translateHrs(diffHours){
		const hoursText = {
            'us': `${diffHours} hours ago`,
            'de': `vor ${diffHours} Stunden`,
            'es': `Hace ${diffHours} horas`,
            'fr': `Il y a ${diffHours} heures`,
            'pt': `${diffHours} horas atrás`,
        };
        return hoursText[`${locale}`];
	}
}

export default _.catch(setDate);


import _ from '../../common/utility';

function alerts () {

let userState = pageParams.userState, 
	breakCont = ".hp_alerts:not(#state-alerts) .section-container",
    stateCont = $("#state-alerts .section-container"),
    artUrl = $(".hp_alerts:not(#state-alerts) li a").attr("href");

stateCont.each((i, ele) => {
  let dataStates = $(ele).attr("data-states");
  if(dataStates.indexOf(userState)>-1) {
    $(ele).show();
  }
});

if($(`${breakCont} ul li`).length) 
	$(`${breakCont}`).show();

$(`${breakCont} .more a`).attr('href',artUrl);
}


export default _.catch(alerts);

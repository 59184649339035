import _ from '../../common/utility';

function featured() {		
	try {
		if (typeof s_user_group != 'undefined' && $.trim(s_user_group) == 'Optometrist') {
			$('.hp.Ophthalmology').addClass('optometrist');
		}
	}
	catch(e) { console.log(e); }

	if ($('#country-content').length > 0) {
		var usrCtry = $('#country-content .section-container').attr('data-country');
		if (typeof usrCtry != 'undefined' && usrCtry.trim() != '' && typeof DFPTargetKeys != 'undefined' && usrCtry.indexOf(DFPTargetKeys.userSegVars.ct.trim()) != -1) {
			$('#country-content').addClass('active');
		}
	}
}

export default _.catch(featured);

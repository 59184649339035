import _ from '../../common/utility';

function callouts () {
    nativeCallouts();
    pbaCallout();
}

function nativeCallouts () {

    // because featured news and perspective don't have any identifiers, we use html positions to find them
    // featured news would always be above hp_news
    // perspective would always be under hp_news

    // placement rules:
    // 3rd slot in Featured
    // Last slot in News
    // Last slot in Perspectives

    const item = $('.nativecallouts-item');

    if (item.length === 0) {
        return;
    }

    if (!item.eq(0).is(':empty')) {
        $('.hp_news')
            .prevAll('.hp_bucket2col').eq(0)
            .find('li').eq(2)
            .replaceWith(
                $('<li class="nativecallouts-item">')
                    .append(item.eq(0).children())
                    .find('a').click(function () {
                    wmdTrack('hp-feat_callout');
                })
                    .end()
            );
    }

    if (!item.eq(1).is(':empty')) {
        $('.hp_news li:not(.ad-container)')
            .last()
            .replaceWith(
                $('<li class="nativecallouts-item">')
                    .append(item.eq(1).children())
                    .find('a').click(function () {
                    wmdTrack('hp-news_callout');
                })
                    .end()
            );
    }

    if (!item.eq(2).is(':empty')) {
        let trackVal = item.eq(3).children().hasClass('board-blog') ? 'hp-blog_board' : 'hp-perspective_callout';
        $('.hp_news')
            .nextAll('.hp_bucket2col').eq(0)
            .find('li').eq(3)
            .replaceWith(
                $('<li class="nativecallouts-item">')
                    .append(item.eq(2).children())
                    .find('a').click(function () {
                    wmdTrack(trackVal);
                })
                    .end()
            );
    }
}

function pbaCallout () {
    // PBA driver
    $.get('/public/noscan/homepage/pba')
        .then(function(data) {
            $('section[data-track="hp-bom"]')
                .find('li').eq(3)
                .replaceWith(
                    $('<li class="nativecallouts-item">')
                        .css('margin-bottom', '20px')
                        .append(data)
                        .find('a').click(function () {
                        wmdTrack('hp-bom_pba');
                    }).end()
                );
        })
}

export default _.catch(callouts);